import React from "react"
import { Helmet } from "react-helmet"

const LuckyOrange = () => {
  return (
    <Helmet>
      {process.env.NODE_ENV === "production" && (
        <script>
          {`
          window.__lo_site_id = 234323
          ;(function() {
            var wa = document.createElement("script")
            wa.type = "text/javascript"
            wa.async = true
            wa.src = "https://d10lpsik1i8c69.cloudfront.net/w.js"
            var s = document.getElementsByTagName("script")[0]
            s.parentNode.insertBefore(wa, s)
          })()
        `}
        </script>
      )}
    </Helmet>
  )
}

export default LuckyOrange
