import React from "react"
import {
  colors,
  fontDefault,
  fontSizes,
  iconSizes,
} from "../../../assets/themes/default"
import { Link } from "gatsby"
import Icon from "../../shared/Icon"
import styled from "@emotion/styled"

const StyledLink = ({
  children,
  hasShadow,
  icon,
  isAlternative,
  isBright,
  isButton,
  isExternal,
  isNavBarItem,
  isPrimary,
  isSecondary,
  isSmall,
  notPadded,
  to,
  ...rest
}) => {
  let styleLink = {
    border: `none`,
    borderRadius: `0`,
    boxSizing: `border-box`,
    color: colors.textDark,
    cursor: `pointer`,
    display: `inline-block`,
    fontFamily: fontDefault,
    fontSize: fontSizes.normal,
    fontWeight: `300`,
    margin: `0 0.3em 0.3em 0`,
    padding: `0.3em 1.2em`,
    textAlign: `center`,
    textDecoration: `underline`,
    transition: `all 0.3s`,
    "&:hover": {
      color: colors.blue,
    },
  }

  if (isPrimary) {
    styleLink = {
      ...styleLink,
      backgroundColor: colors.blue,
      borderRadius: `2em`,
      color: colors.white,
      fontWeight: `600`,
      textDecoration: `none`,
      "&:hover": {
        backgroundColor: colors.blueHigh,
      },
    }
  }

  if (isSecondary) {
    styleLink = {
      ...styleLink,
      backgroundColor: colors.white,
      borderRadius: `2em`,
      color: colors.grayDark,
      fontWeight: `600`,
      textDecoration: `none`,
      "&:hover": {
        backgroundColor: colors.blueHigh,
        color: colors.white,
      },
    }
  }

  if (isAlternative) {
    styleLink = {
      ...styleLink,
      backgroundColor: colors.orange,
      borderRadius: `2em`,
      color: colors.white,
      fontWeight: `600`,
      textDecoration: `none`,
      "&:hover": {
        backgroundColor: colors.orangeHigh,
      },
    }
  }

  if (isNavBarItem) {
    styleLink = {
      ...styleLink,
      backgroundColor: colors.white,
      border: "none",
      borderRadius: "0",
      boxSizing: "border-box",
      color: colors.textDark,
      cursor: "pointer",
      display: "inline-block",
      fontFamily: fontDefault,
      fontSize: fontSizes.normal,
      fontWeight: "300",
      margin: "0 0.3em 0.3em 0",
      padding: "0.3em 1.2em",
      textAlign: "center",
      textDecoration: "underline",
      transition: "all 0.3s",
      "&:hover": {
        backgroundColor: colors.textBright,
      },
    }
  }

  if (isSmall) {
    styleLink = {
      ...styleLink,
      fontSize: fontSizes.xsmall,
      padding: `0em 0.8em`,
    }
  }

  if (isBright) {
    styleLink = {
      ...styleLink,
      color: `${colors.textBright}`,
      "&:hover": {
        color: `${colors.blueHigh}`,
      },
    }
  }

  if (notPadded) {
    styleLink = {
      ...styleLink,
      padding: `0px`,
    }
  }

  if (hasShadow) {
    styleLink = {
      ...styleLink,
      boxShadow: `0px 8px 15px rgba(0, 0, 0, 0.2)`,
    }
  }

  let StyledLink = {}

  if (isButton) {
    StyledLink = styled.button`
      ${styleLink}
    `
  } else if (isExternal) {
    StyledLink = styled.a`
      ${styleLink}
    `
  } else {
    StyledLink = styled(Link)`
      ${styleLink}
    `
  }

  let buttonIcon = null
  if (icon) {
    buttonIcon = (
      <Icon
        name={icon}
        size={iconSizes.small}
        style={{ paddingRight: `10px` }}
      />
    )
  }

  return (
    <>
      {isExternal && (
        <StyledLink href={to} target="_blank" {...rest}>
          {buttonIcon}
          {children}
        </StyledLink>
      )}
      {!isExternal && (
        <StyledLink to={to} {...rest}>
          {buttonIcon}
          {children}
        </StyledLink>
      )}
    </>
  )
}

export default StyledLink
