import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Footer from "./shared/Footer"
import GDPRConsent from "./shared/GDPRConsent"
import LuckyOrange from "./shared/LuckyOrange"
import NavBar from "./shared/NavBar"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import "../assets/themes/default.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const styleWrapper = {
    margin: `0 auto`,
    marginTop: `80px`,
    maxWidth: `1200px`,
    padding: `0px`,
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  return (
    <div style={{  overflow: `hidden`  }}>
      <LuckyOrange />
      <NavBar siteTitle={data.site.siteMetadata.title} />
      <Wrapper>
        <main>{children}</main>
      </Wrapper>
      <GDPRConsent />
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
