import React from "react"
import { colors, fontDefault, fontSizes } from "../../../assets/themes/default"
import styled from "@emotion/styled"

const Text = ({
  type,
  color = `${colors.grayDark}`,
  size,
  isBold,
  isItalic,
  noBreak,
  noMargins,
  children,
  ...rest
}) => {
  let style = {
    color: `${color}`,
    fontFamily: `${fontDefault}`,
    fontSize: `${fontSizes.normal}`,
    fontWeight: `300`,
    lineHeight: `1.5rem`,
    marginBottom: `1rem`,
    userSelect: `none`,
  }

  if (isBold) {
    style = {
      ...style,
      fontWeight: `600`,
    }
  }

  if (isItalic) {
    style = {
      ...style,
      fontStyle: `italic`,
    }
  }

  if (noBreak) {
    style = {
      ...style,
      display: `inline-block`,
    }
  }

  if (noMargins) {
    style = {
      ...style,
      margin: `0px`,
    }
  }

  let StyledTag

  switch (type) {
    case "title":
      style = {
        ...style,
        fontSize: `${fontSizes.xlarge}`,
        fontWeight: `600`,
        lineHeight: `2.4rem`,
      }
      StyledTag = styled.h1`
        ${style}
      `
      break
    case "subtitle":
      style = {
        ...style,
        fontSize: fontSizes.large,
        lineHeight: `2rem`,
      }
      StyledTag = styled.h2`
        ${style}
      `
      break
    case "subsubtitle":
      style = {
        ...style,
        fontSize: fontSizes.medium,
      }
      StyledTag = styled.h3`
        ${style}
      `
      break
    case "small":
      style = {
        ...style,
        fontSize: fontSizes.small,
        lineHeight: `1.2rem`,
      }
      StyledTag = styled.p`
        ${style}
      `
      break
    case "xsmall":
      style = {
        ...style,
        fontSize: fontSizes.xsmall,
        lineHeight: `1.2rem`,
      }
      StyledTag = styled.p`
        ${style}
      `
      break
    case "intro":
      style = {
        ...style,
        fontSize: fontSizes.medium,
        lineHeight: `1.8rem`,
      }
      StyledTag = styled.p`
        ${style}
      `
      break
    default:
      StyledTag = styled.p`
        ${style}
      `
      break
  }

  // Custom text size
  if (typeof size != undefined) {
    style = {
      ...style,
      fontSize: `${size} !important`,
    }
    StyledTag = styled(StyledTag)`
      ${style}
    `
  }

  return <StyledTag {...rest}>{children}</StyledTag>
}

export default Text
