import { css } from "@emotion/core"

export const GLOBAL_FONT_SIZE = 14

export const pixelsToRem = (pixels) => {
  const correctionFactor = 0
  return pixels / (GLOBAL_FONT_SIZE + correctionFactor) + "rem"
}

export const colors = {
  blue: `hsla(205, 100%, 59%, 1)`,
  blueHigh: `hsla(215, 100%, 59%, 1)`,
  blueLight: `hsla(215, 100%, 95%, 1)`,
  danger: `hsla(354, 70%, 54%, 1)`,
  grayDark: `hsla(0, 0%, 17%, 1)`,
  grayLight: `hsla(0, 0%, 75%, 1)`,
  grayLightX: `hsla(0, 0%, 95%, 1)`,
  orange: `hsla(25, 100%, 59%, 1)`,
  orangeHigh: `hsla(35, 100%, 59%, 1)`,
  success: `hsla(134, 61%, 41%, 1)`,
  textBright: `hsla(0, 0%, 90%, 1)`,
  textDark: `hsla(0, 0%, 17%, 1)`,
  violet: `hsla(274, 38%, 37%, 1)`,
  warning: `hsla(hsl(45, 100%, 51%, 1)`,
  white: `hsla(0, 0%, 100%, 1)`,
}

export const fontDefault = '"Raleway", sans-serif'

export const fontSizes = {
  xsmall: pixelsToRem([10]),
  small: pixelsToRem([12]),
  normal: pixelsToRem([14]),
  medium: pixelsToRem([18]),
  large: pixelsToRem([24]),
  xlarge: pixelsToRem([28]),
  xxlarge: pixelsToRem([36]),
  xxxlarge: pixelsToRem([48]),
}

export const iconSizes = {
  xsmall: "12px",
  small: "16px",
  normal: "32px",
  large: "48px",
  xlarge: "64px",
}

String.prototype.trunc = function (n, useWordBoundary) {
  if (this.length <= n) {
    return this
  }
  var subString = this.substr(0, n - 1)
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  )
}

export const formattedDateFromString = (stringDate) => {
  const d = new Date(stringDate)
  const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d)
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
  return `${mo} ${da}, ${ye}`
}

export default css`
  :root {
    --color-blue: ${colors.blue};
    --color-gray-dark: ${colors.grayDark};
    --color-gray-light: ${colors.textBright};
    --color-gray-light-x: ${colors.grayLightX};
    --color-text-dark: ${colors.grayDark};
    --color-text-bright: ${colors.textBright};
  }
`

export const addToObject = (obj, key, value, index) => {
  let temp = {}
  let count = 0
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (count === index && key && value) {
        temp[key] = value
      }
      temp[prop] = obj[prop]
      count++
    }
  }
  if (!index && key && value) {
    temp[key] = value
  }
  return temp
}
