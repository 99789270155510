import React, { useState } from "react"
import { colors } from "../../../assets/themes/default"
import styled from "@emotion/styled"
import StyledLink from "../StyledLink"
import Text from "../Text"

const styleContainer = {
  background: `rgba(0,0,0,0.9)`,
  bottom: `0`,
  display: `none`,
  left: `0`,
  margin: `0 auto`,
  padding: `30px`,
  position: `fixed`,
  textAlign: `center`,
  width: `100%`,
  zIndex: `9999999999`,
}

const Container = styled.div`
  ${styleContainer}
`

const GDPRConsent = () => {
  const [dismissed, setDismissed] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("meteor-gdpr-consent") === "true"
      : true
  )

  const handleClick = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("meteor-gdpr-consent", true)
    }
    setDismissed(true)
  }

  return (
    <>
      {!dismissed && (
        <Container style={{ display: dismissed ? "none" : "inline-block" }}>
          <Text color={colors.white}>
            We use cookies to improve user experience and analyze website
            traffic. For more information read our{" "}
            <StyledLink isBright notPadded to={"/privacy-policy"}>
              Privacy Policy
            </StyledLink>
          </Text>
          <StyledLink isButton hasShadow isAlternative onClick={handleClick}>
            Dismiss
          </StyledLink>
        </Container>
      )}
    </>
  )
}

export default GDPRConsent
