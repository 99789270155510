import React, { useState } from "react"
import { colors } from "../../../assets/themes/default"
import { Link } from "gatsby"
import CustomAnchorLink from "../CustomAnchorLink"
import Icon from "../Icon"
import Image from "../Image"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import StyledLink from "../StyledLink"

const NavBar = () => {
  const [showPopupMenu, setShowPopupMenu] = useState(false)
  const togglePopupMenu = () => {
    setShowPopupMenu((showPopupMenu) => !showPopupMenu)
  }

  let styleNavBar = {
    background: `${colors.white}`,
    boxShadow: `0px 5px 5px rgba(0, 0, 0, 0.25)`,
    color: `${colors.grayDark}`,
    left: `50%`,
    margin: `0px -50vw 1.45rem -50vw`,
    position: `fixed`,
    right: `50%`,
    top: 0,
    width: `100vw`,
    zIndex: `1000`,
  }

  const styleWrapperLogo = {
    textAlign: `left`,
    "@media (max-width:800px)": {
      width: `100%`,
      textAlign: `center`,
    },
  }

  const styleLogo = {
    maxWidth: `250px`,
    minWidth: `150px`,
  }

  const styleWrapper = {
    alignItems: `center`,
    display: `flex`,
    height: `100px`,
    justifyContent: `space-between`,
    margin: `0px auto`,
    maxWidth: `1200px`,
    padding: `20px`,
    "@media (max-width:800px)": {
      paddingRight: `30px`,
    },
  }

  const styleLinksDesktop = {
    textAlign: `right`,
    "@media (max-width:800px)": {
      display: `none`,
    },
  }

  const styleLinksMobile = {
    textAlign: `right`,
    "@media (min-width:801px)": {
      display: `none`,
    },
  }

  const styleButtonMenu = {
    border: `none`,
    background: `#ffffff`,
  }

  const stylePopUpMenu = {
    paddingBottom: `10px`,
    paddingRight: `20px`,
    textAlign: `center`,
    width: `100%`,
    "@media (min-width:801px)": {
      display: `none`,
    },
  }

  const NavBar = styled.header`
    ${styleNavBar}
  `

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const WrapperLogo = styled.div`
    ${styleWrapperLogo}
  `

  const Logo = styled(Image)`
    ${styleLogo}
  `

  const LinksDesktop = styled.div`
    ${styleLinksDesktop}
  `

  const LinksMobile = styled.div`
    ${styleLinksMobile}
  `

  const ButtonMenu = styled.button`
    ${styleButtonMenu}
  `

  const PopUpMenu = styled.div`
    ${stylePopUpMenu}
  `

  return (
    <NavBar>
      <Wrapper>
        <WrapperLogo>
          <Link to={`/`}>
            <Logo source={`/images/meteor-logo-header.svg`} />
          </Link>
        </WrapperLogo>
        <LinksDesktop>
          <CustomAnchorLink to={`/#WhatWeDo`} title={`What we do?`} stripHash>
            <span>What we do?</span>
          </CustomAnchorLink>
          <CustomAnchorLink to={`/#WhyUs`} title={`Why Us?`} stripHash>
            <span>Why Us?</span>
          </CustomAnchorLink>
          <StyledLink to={`/blogposts`} title={`From the Blog`} isNavBarItem>
            Blog
          </StyledLink>
          <CustomAnchorLink
            to={`/#CaseStudies`}
            title={`Case Studies`}
            stripHash
          >
            <span>Case Studies</span>
          </CustomAnchorLink>
          <StyledLink to={`/pricing`} title={`Pricing`} isNavBarItem>
            Pricing
          </StyledLink>
          <CustomAnchorLink to={`/#Calendly`} title={`Get in Touch`} stripHash>
            <span>Get in Touch</span>
          </CustomAnchorLink>
          <StyledLink
            isPrimary
            isExternal
            icon={`phone-alt`}
            to={`tel:7047505604`}
            title={`Call Us!`}
          >
            704 750 5604
          </StyledLink>
        </LinksDesktop>
        <LinksMobile>
          <ButtonMenu href="#" onClick={togglePopupMenu}>
            <Icon name="menu" />
          </ButtonMenu>
        </LinksMobile>
      </Wrapper>
      {showPopupMenu ? (
        <PopUpMenu>
          <StyledLink to="/" title={`Home`} isNavBarItem>
            Home
          </StyledLink>
          <StyledLink to={`/blogposts`} title={`From the Blog`} isNavBarItem>
            Blog
          </StyledLink>
          <StyledLink to={`/case-studies`} title={`Case Studies`} isNavBarItem>
            Case Studies
          </StyledLink>
          <StyledLink href={`/pricing`} title={`Pricing`} isNavBarItem>
            Pricing
          </StyledLink>
          <StyledLink
            isPrimary
            isExternal
            icon={`phone-alt`}
            to={`tel:7047505604`}
            title={`Call Us!`}
          >
            704 750 5604
          </StyledLink>
        </PopUpMenu>
      ) : (
        ""
      )}
    </NavBar>
  )
}

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}

export default NavBar
