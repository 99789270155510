import React from "react"
import { iconSizes, fontSizes, colors } from "../../../assets/themes/default"
import { Link } from "gatsby"
import Icon from "../../shared/Icon"
import Image from "../Image"
import styled from "@emotion/styled"
import Text from "../Text"

const Footer = () => {
  const stylesFooter = {
    backgroundColor: `${colors.grayDark}`,
    color: `${colors.textBright}`,
    fontFamily: `Raleway, sans-serif`,
    fontSize: fontSizes.normal,
    width: `100%`,
  }

  const stylesContainer = {
    margin: `0 auto`,
    maxWidth: `1200px`,
    padding: `50px 20px`,
  }

  const stylesCopyright = {
    borderTop: `1px solid ${colors.grayLight}`,
    paddingTop: `10px`,
    textAlign: `right`,
  }

  const stylesLink = {
    color: `${colors.white}`,
    "&:hover": {
      color: `${colors.blue}`,
    },
    "&:visited": {
      color: `${colors.white}`,
    },
  }

  const StyledFooter = styled.footer`
    ${stylesFooter}
  `

  const StyledContainer = styled.div`
    ${stylesContainer}
  `

  const StyledCopyright = styled.div`
    ${stylesCopyright}
  `

  const StyledLink = styled(Link)`
    ${stylesLink}
  `

  const StyledPhoneLink = styled.a`
    ${stylesLink}
  `

  return (
    <StyledFooter>
      <StyledContainer>
        <Image
          source="../images/meteor-logo-footer.svg"
          style={{ width: `190px` }}
        />
        <Text
          color={colors.grayLight}
          size={fontSizes.xsmall}
          style={{ marginLeft: `55px` }}
        >
          <Icon name="phone-alt" size={iconSizes.xsmall} />
          &nbsp;{" "}
          <StyledPhoneLink href="tel:704 750 5604">
            704 750 5604
          </StyledPhoneLink>
          <br />
          <Icon name="map-marker-alt" size={iconSizes.xsmall} />
          &nbsp; Charlotte, NC
        </Text>
        <StyledCopyright>
          <Text color={colors.grayLight} size={fontSizes.xsmall}>
            Copyright &copy; {new Date().getFullYear()} Meteor Affinity. All
            rights reserved
            <br />
            <StyledLink to={`/privacy-policy`}>Privacy Policy</StyledLink>
            &nbsp;|&nbsp;
            <StyledLink to={`/terms-and-conditions`}>
              Terms and Conditions
            </StyledLink>
          </Text>
        </StyledCopyright>
      </StyledContainer>
    </StyledFooter>
  )
}

export default Footer
