import React from "react"
import { iconSizes } from "../../../assets/themes/default"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Icon = ({ name, size, ...rest }) => {
  const style = {
    display: `inline-block`,
    height: `${size}`,
    verticalAlign: `middle`,
    width: `auto`,
  }

  const Icon = styled.img`
    ${style}
  `

  return <Icon src={`/icons/${name}.svg`} {...rest} />
}

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
}

Icon.defaultProps = {
  size: iconSizes.normal,
}

export default Icon
