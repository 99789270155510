import React from "react"
import styled from "@emotion/styled"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { fontDefault, fontSizes, colors } from "../../../assets/themes/default"

const CustomAnchorLink = ({ children, isNavbarVertical, ...rest }) => {
  let style = {
    backgroundColor: colors.white,
    border: "none",
    borderRadius: "0",
    boxSizing: "border-box",
    color: colors.textDark,
    cursor: "pointer",
    display: "inline-block",
    fontFamily: fontDefault,
    fontSize: fontSizes.normal,
    fontWeight: "300",
    margin: "0 0.3em 0.3em 0",
    padding: "0.3em 1.2em",
    textAlign: "center",
    textDecoration: "underline",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: colors.textBright,
    },
  }

  if (isNavbarVertical) {
    style = {
      ...style,
      borderTop: `1px solid ${colors.grayLight}`,
      display: `block`,
      margin: "0px",
      padding: `20px`,
      paddingLeft: `40px`,
      textAlign: `left`,
    }
  }

  const StyledAnchorLink = styled(AnchorLink)`
    ${style}
  `

  return <StyledAnchorLink {...rest}>{children}</StyledAnchorLink>
}

export default CustomAnchorLink
