import React from "react"
import { colors } from "../../../assets/themes/default"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Text from "../Text"

const Section = ({
  bgColor,
  bgImage,
  bgImageRepeat,
  children,
  color,
  isFullWidth,
  subtitle,
  title,
  ...rest
}) => {
  let styleWrapper = {
    backgroundColor: bgColor,
    paddingBottom: `50px`,
    paddingTop: `50px`,
    textAlign: `center`,
  }

  const styleHeader = {
    margin: `0 auto`,
    maxWidth: `900px`,
    padding: `0 20px 0 20px`,
    textAlign: `left`,
  }

  const styleTitle = {
    textAlign: `center`,
  }

  let styleBody = {
    alignItems: `flex-start`,
    display: `flex`,
    flexDirection: `row`,
    flexWrap: `wrap`,
    justifyContent: `space-around`,
    margin: `0 auto`,
    textAlign: `left`,
    "@media (max-width:800px)": {
      alignItems: `center`,
      flexDirection: `column`,
      width: `100%`,
    },
  }

  if (isFullWidth) {
    styleWrapper = {
      ...styleWrapper,
      left: `50%`,
      marginLeft: `-50vw`,
      marginRight: `-50vw`,
      position: `relative`,
      right: `50%`,
      width: `100vw`,
    }
    styleBody = {
      ...styleBody,
      maxWidth: `1200px`,
    }
  }

  if (bgImage) {
    styleWrapper = {
      ...styleWrapper,
      backgroundImage: `url(${bgImage})`,
      backgroundSize: `cover`,
    }
    if (bgImageRepeat) {
      styleWrapper = {
        ...styleWrapper,
        backgroundPosition: `center`,
        backgroundRepeat: `repeat`,
        backgroundSize: `auto`,
      }
    }
  }

  const Wrapper = styled.section`
    ${styleWrapper}
  `

  const Header = styled.div`
    ${styleHeader}
  `

  const Title = styled(Text)`
    ${styleTitle}
  `

  const Body = styled.div`
    ${styleBody}
  `

  return (
    <Wrapper {...rest}>
      <Header>
        <Title type="title" color={color}>
          {title}
        </Title>
        <Text type="intro" color={color}>
          {subtitle}
        </Text>
      </Header>
      <Body>{children}</Body>
    </Wrapper>
  )
}

Section.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node,
  isFullWidth: PropTypes.bool,
}

Section.defaultProps = {
  bgColor: `${colors.white}`,
  isFullWidth: false,
}

export default Section
